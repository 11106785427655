<template>
  <div>
    <el-row>
      <el-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess">
      </el-upload>
    </el-row>

    <el-form ref="el-form" label-width="100px" :rules="rules" :model="paper" class="demo-ruleForm" label-position="left">
      <el-form-item label="题目" prop="paperTopic">
        <el-input v-model="paper.paperTopic"></el-input>
      </el-form-item>

      <el-form-item label="A" prop="paperOne">
        <!--        <quill-editor-->
        <!--            ref="myQuillEditor1"-->
        <!--            v-model="paper.paperOne"-->
        <!--            :options="editorOption"-->
        <!--            @blur="onEditorBlur($event)"-->
        <!--            @focus="onEditorFocus($event,'myQuillEditor1')"-->
        <!--            @ready="onEditorReady($event)"-->
        <!--        />-->
        <el-input v-model="paper.paperOne" type="input"></el-input>
      </el-form-item>
      <el-form-item label="B" prop="paperTwo">
        <el-input v-model="paper.paperTwo" type="input"></el-input>
        <!--        <quill-editor-->
        <!--            ref="myQuillEditor2"-->
        <!--            v-model="paper.paperTwo"-->
        <!--            :options="editorOption"-->
        <!--            @blur="onEditorBlur($event)"-->
        <!--            @focus="onEditorFocus($event,'myQuillEditor2')"-->
        <!--            @ready="onEditorReady($event)"-->
        <!--        />-->
      </el-form-item>
      <el-form-item label="C" prop="paperThree">
        <el-input v-model="paper.paperThree" type="input"></el-input>
        <!--        <quill-editor-->
        <!--            ref="myQuillEditor3"-->
        <!--            v-model="paper.paperThree"-->
        <!--            :options="editorOption"-->
        <!--            @blur="onEditorBlur($event)"-->
        <!--            @focus="onEditorFocus($event,'myQuillEditor3')"-->
        <!--            @ready="onEditorReady($event)"-->
        <!--        />-->
      </el-form-item>
      <el-form-item label="D" prop="paperFour">
        <el-input v-model="paper.paperFour" type="input"></el-input>
        <!--        <quill-editor-->
        <!--            ref="myQuillEditor4"-->
        <!--            v-model="paper.paperFour"-->
        <!--            :options="editorOption"-->
        <!--            @blur="onEditorBlur($event)"-->
        <!--            @focus="onEditorFocus($event,'myQuillEditor4')"-->
        <!--            @ready="onEditorReady($event)"-->
        <!--        />-->
      </el-form-item>

      <el-form-item label="答案" prop="paperAnswer">
        <el-radio-group v-model="paper.paperAnswer">
          <el-radio label="A">A</el-radio>
          <el-radio label="B">B</el-radio>
          <el-radio label="C">C</el-radio>
          <el-radio label="C">D</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="题目解释" prop="paperExplain">
        <el-input v-model="paper.paperExplain" placeholder="请输入解释"></el-input>
      </el-form-item>
      <el-form-item label="题目难度" prop="paperRank">
        <el-rate v-model="paper.paperRank"></el-rate>
      </el-form-item>

      <el-form-item label="添加">
        <el-button @click="add">添加选择题</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {quillEditor} from "vue-quill-editor";
import global from "@/config/global";
import {addPaper} from "@/api/paper";

export default {
  //添加选择题的地方
  name: "One",
  props: ['id'],
  components: {
    // quillEditor
  },
  data() {
    return {
      // 题目的内容
      paper: {
        paperTopic: "",
        paperRank: 0,
        userId: this.$store.state.user.id,
        pid: "",
        paperType: 1,
        paperOne: "",
        paperTwo: "",
        paperThree: "",
        paperFour: "",
        paperAnswer: "",
        paperExplain: "",
      },
      rules: {
        paperTopic: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        paperOne: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        paperTwo: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        paperThree: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        paperFour: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        paperAnswer: [{
          required: true,
          message: '请输入答案',
          trigger: 'blur'
        }],
        paperExplain: [{
          required: true,
          message: '请输入解释',
          trigger: 'blur'
        }],
        paperRank: [{
          required: true,
          message: '请输入题目难度',
          trigger: 'blur'
        }],
      },
      uploadUrl: global.uploadUrl,
      now: "myQuillEditor1",
      editorOption: {
        //  富文本编辑器配置
        modules: {
          //工具栏定义的
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
            ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
            [{header: 1}, {header: 2}], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
            [{list: "ordered"}, {list: "bullet"}], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
            [{script: "sub"}, {script: "super"}], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
            [{indent: "-1"}, {indent: "+1"}], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
            [{direction: "rtl"}], // 文本方向-----[{'direction': 'rtl'}]
            [{size: ["small", false, "large", "huge"]}], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
            [{header: [1, 2, 3, 4, 5, 6, false]}], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
            [{color: []}, {background: []}], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
            [{font: []}], // 字体种类-----[{ font: [] }]
            [{align: []}], // 对齐方式-----[{ align: [] }]
            ["clean"], // 清除文本格式-----['clean']
            ["image", "video"] // 链接、图片、视频-----['link', 'image', 'video']
          ],
          // handlers: {
          //   // 重写点击组件上的图片按钮要执行的代码
          //   'image': function (value) {
          //     document.querySelector('.avatar-uploader .el-icon-upload').click()
          //   }
          // }
        },
        //主题
        theme: "snow",
        placeholder: "请输入正文"
      },
    }
  },
  mounted() {
    if (this.id !== undefined || this.id !== "") {
      this.paper.pid = this.id;
    }
  },
  methods: {
    // 添加文章
    add() {
      this.$refs['el-form'].validate((valid) => {
        if (valid) {
          addPaper(this.paper).then(result => {
            this.$message.success(result.data.paper)
          }).catch(error => {
            console.log(error)
            this.$message.error(error.message)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 图片上传
    handleAvatarSuccess(res) {

      const quill = this.$refs[this.now].quill
      // 获取光标位置
      const pos = quill.getSelection().index
      // 插入图片到光标位置
      quill.insertEmbed(pos, 'image', res.data.url);

    },
    //失去焦点事件
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    //获得焦点事件
    onEditorFocus(quill, now) {
      console.log('editor focus!', quill);
      // 设置当前放置的富文本编辑器
      this.now = now;
    },
    // 准备富文本编辑器
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    //内容改变事件
    onEditorChange({quill, html, text}) {
      console.log('editor change!', quill, html, text)
      this.content = html
    }
  }
}
</script>

<style scoped>
.avatar-uploader {
  display: none;
}
</style>
