<template>
  <div>
    <el-form ref="el-form" :model="paper" :rules="rules" label-width="100px" label-position="left">
      <el-form-item label="题目内容" prop="paperTopic">
        <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="paper.paperTopic">
        </el-input>
      </el-form-item>
      <el-form-item label="简答题答案" prop="paperAnswer">
        <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="paper.paperAnswer">
        </el-input>
      </el-form-item>

      <el-form-item label="解释" prop="paperExplain">
        <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="paper.paperExplain">
        </el-input>
      </el-form-item>
      <el-form-item label="题目难度" prop="paperRank">
        <el-rate v-model="paper.paperRank"></el-rate>
      </el-form-item>

      <el-form-item label="添加">
        <el-button @click="add">添加简答题</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
//简答题
import {addPaper} from "@/api/paper";

export default {
  name: "ShortAnswer",
  props: ['id'],
  data() {
    return {
      // 题目的内容
      paper: {
        paperTopic: "",
        paperRank: 0,
        userId: this.$store.state.user.id,
        pid: "",
        paperType: 4,
        paperOne: "",
        paperTwo: "",
        paperThree: "",
        paperFour: "",
        paperAnswer: "",
        paperExplain: "",
      },
      rules: {
        paperTopic: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        paperOne: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        paperTwo: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        paperThree: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        paperFour: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        paperAnswer: [{
          required: true,
          message: '请输入答案',
          trigger: 'blur'
        }],
        paperExplain: [{
          required: true,
          message: '请输入解释',
          trigger: 'blur'
        }],
        paperRank: [{
          required: true,
          message: '请输入题目难度',
          trigger: 'blur'
        }],
      },
    }
  },
  mounted() {
    if (this.id !== undefined || this.id !== "") {
      this.paper.pid = this.id;
    }
  },

  methods: {
    // 添加文章
    add() {
      this.$refs['el-form'].validate((valid) => {
        if (valid) {
          addPaper(this.paper).then(result => {
            this.$message.success(result.data.paper)
          }).catch(error => {
            console.log(error)
            this.$message.error(error.message)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
