<template>
  <div class="addPaper">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="选择题" name="first">
        <AddChoose></AddChoose>
      </el-tab-pane>
      <el-tab-pane label="判断题" name="second">
        <AddJudge></AddJudge>
      </el-tab-pane>
      <el-tab-pane label="填空题" name="third">
        <AddCompletion></AddCompletion>
      </el-tab-pane>
      <el-tab-pane label="简答题" name="fourth">
        <AddShortAnswer></AddShortAnswer>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AddChoose from "@/views/paper/common/AddChoose";
import AddCompletion from "@/views/paper/common/AddCompletion";
import AddJudge from "@/views/paper/common/AddJudge";
import AddShortAnswer from "@/views/paper/common/AddShortAnswer";

export default {
  name: "add-paper",
  components:{
    AddShortAnswer,
    AddCompletion,
    AddChoose,
    AddJudge
  },
  data(){
    return{
      activeName: 'second'
    }
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    }
  }
}
</script>

<style scoped>
.addPaper{
  background-color: #fff;
  padding: 20px;
  min-height: calc(100vh - 120px);
}
</style>